import type { TDocumentDefinitions } from 'pdfmake/interfaces'
import { documentDefaultStyle, documentImages, documentMargins, documentStyles, empty, nl } from '@/helper/document';
import { DocumentField } from '@/use/document';
import moment from 'moment';

export interface ResponsibilityVariables {
  [key: string]: any;
}

export const fields: Array<DocumentField> = [
  // Header
  {
    type: "divider",
    name: "headerDivider",
    attrs: { label: "Nagłówek" },
  },
  {
    type: "date",
    name: "validFrom",
    attrs: { label: "Obowiązuje od" },
  },
  {
    type: "text",
    name: "place",
    attrs: { label: "Miejscowość" },
  },
  {
    type: "date",
    name: "date",
    attrs: { label: "Dnia" },
  },
  {
    type: "time",
    name: "time",
    attrs: { label: "Godz." },
  },
  // Company
  {
    type: "divider",
    name: "companyDivider",
    attrs: { label: "Firma" },
  },
  {
    type: "text",
    name: "companyName",
    attrs: { label: "Nazwa firmy" },
  },
  {
    type: "text",
    name: "companyAddress",
    attrs: { label: "Adres firmy" },
  },
  {
    type: "text",
    name: "companyNumber",
    attrs: { label: "Numer firmy" },
  },
  // Document
  {
    type: "divider",
    name: "documentDivider",
    attrs: { label: "Dokument" },
  },
  {
    type: "text",
    name: "documentNumber",
    attrs: { label: "Numer umowy" },
  },
  {
    type: "date",
    name: "documentDate",
    attrs: { label: "Data zwarcia" },
  },
  {
    type: "text",
    name: "documentPlace",
    attrs: { label: "Miejscowość zawarcia" },
  },
  // Employer
  {
    type: "divider",
    name: "employerDivider",
    attrs: { label: "Zleceniodawca" },
  },
  {
    type: "text",
    name: "employerName",
    attrs: { label: "Firma zawierająca" },
  },
  {
    type: "text",
    name: "employerHQ",
    attrs: { label: "...z siedzibą..." },
  },
  {
    type: "text",
    name: "employerEntered",
    attrs: { label: "...wpisaną przez..." },
  },
  {
    type: "text",
    name: "employerKRS",
    attrs: { label: "KRS" },
  },
  {
    type: "text",
    name: "employerREGON",
    attrs: { label: "REGON" },
  },
  {
    type: "text",
    name: "employerNIP",
    attrs: { label: "NIP" },
  },
  {
    type: "text",
    name: "employerRep",
    attrs: { label: "...reprezentowaną przez..." },
  },
  // Employee
  {
    type: "divider",
    name: "employeeDivider",
    attrs: { label: "Zleceniobiorca" },
  },
  {
    type: "text",
    name: "employeeName",
    attrs: { label: "Imię i nazwisko" },
  },
  {
    type: "text",
    name: "employeeCardSeries",
    attrs: { label: "Seria dowodu osobistego" },
  },
  {
    type: "text",
    name: "employeeCardNumber",
    attrs: { label: "Numer dowodu osobistego" },
  },
  {
    type: "text",
    name: "employeeAddress",
    attrs: { label: "Adres" },
  },
  {
    type: "text",
    name: "employeePostalcode",
    attrs: { label: "Kod pocztowy" },
  },
  {
    type: "text",
    name: "employeePesel",
    attrs: { label: "PESEL" },
  },
  {
    type: "text",
    name: "employeeLicense",
    attrs: { label: "Nr prawa jazdy" },
  },
  {
    type: "date",
    name: "employeeLicenseValid",
    attrs: { label: "Data ważności prawa jazdy" },
  },
  // #1
  {
    type: "divider",
    name: "n1Divider",
    attrs: { label: "§ 1" },
  },
  {
    type: "date",
    name: "n1date",
    attrs: { label: "Data" },
  },
  {
    type: "textarea",
    name: "n1scope",
    attrs: { label: "Zakres" },
  },
  // #11
  {
    type: "divider",
    name: "n11Divider",
    attrs: { label: "§ 11" },
  },
  {
    type: "text",
    name: "n11t1",
    attrs: { label: "pracowników..." },
  },
  {
    type: "text",
    name: "n11t2",
    attrs: { label: "na rzecz..." },
  },
  // #12
  {
    type: "divider",
    name: "n12Divider",
    attrs: { label: "§ 12" },
  },
  {
    type: "text",
    name: "n12t1",
    attrs: { label: "na rzecz..." },
  },
  // #13
  {
    type: "divider",
    name: "n13Divider",
    attrs: { label: "§ 13" },
  },
  {
    type: "text",
    name: "n13t1",
    attrs: { label: "na koszt..." },
  },
  // #15
  {
    type: "divider",
    name: "n15Divider",
    attrs: { label: "§ 15" },
  },
  {
    type: "date",
    name: "n15t1",
    attrs: { label: "obowiązuje od..." },
  },
  // #16
  {
    type: "divider",
    name: "n16Divider",
    attrs: { label: "§ 16" },
  },
  {
    type: "text",
    name: "n16t1",
    attrs: { label: "poniesiona przez..." },
  },
  {
    type: "text",
    name: "n16t2",
    attrs: { label: "w umowie kar, ... uprawniona jest" },
  },
];

export const variablesToResponsibility = (variables: { [key: string]: any }) => ({
  validFrom: '2019-01-02',

  place: variables.company?.city ?? null,

  date:
    variables.type === "vehicleHandover"
      ? variables.handoverDate
      : variables.returnDate,

  time: moment().format("HH:mm"),

  companyName: variables.company?.name ?? null,

  companyAddress: variables.company
    ? `${variables.company.address}, ${variables.company.postcode} ${variables.company.city}`
    : null,

  companyNumber: variables.company
    ? variables.company.Regon
      ? `REGON ${variables.company.Regon}`
      : variables.company.NIP
        ? `NIP ${variables.company.NIP}`
        : variables.company.KRS
          ? `KRS ${variables.company.KRS}`
          : null
    : null,

  documentDate: variables.type === "vehicleHandover"
    ? variables.handoverDate
    : variables.returnDate,

  documentPlace: variables.company?.city ?? null,

  employerName: variables.company?.name ?? null,

  employerHQ: variables.company
    ? `${variables.company.postcode} ${variables.company.city}, ${variables.company.address}`
    : null,

  employerKRS: variables.company?.KRS ?? null,
  employerREGON: variables.company?.Regon ?? null,
  employerNIP: variables.company?.NIP ?? null,

  employeeName: variables.user ? `${variables.user.firstName} ${variables.user.lastName}` : null,

  n1date: variables.type === "vehicleHandover"
    ? variables.handoverDate
    : variables.returnDate,

  n11t1: variables.company?.name ?? null,
  n11t2: variables.company?.name ?? null,
  n12t1: variables.company?.name ?? null,
  n13t1: variables.company?.name ?? null,
  n15t1: moment().format('YYYY-MM-DD'),
  n16t1: variables.company?.name ?? null,
  n16t2: variables.company?.name ?? null,
})

export default ({
  validFrom,
  place,
  date,
  time,
  companyName,
  companyAddress,
  companyNumber,
  documentNumber,
  documentDate,
  documentPlace,
  employerName,
  employerHQ,
  employerEntered,
  employerKRS,
  employerREGON,
  employerNIP,
  employerRep,
  employeeName,
  employeeCardSeries,
  employeeCardNumber,
  employeeAddress,
  employeePostalcode,
  employeePesel,
  employeeLicense,
  employeeLicenseValid,
  n1date,
  n1scope,
  n11t1,
  n11t2,
  n12t1,
  n13t1,
  n15t1,
  n16t1,
  n16t2
}: ResponsibilityVariables): TDocumentDefinitions => ({
  content: [
    { text: [place ?? empty, ', dnia ', date ?? empty, ' godzina ', time ?? empty], style: ['right', '10'] },
    { text: nl, style: ['7'] },
    { stack: [companyName ?? empty, companyAddress ?? empty, companyNumber ?? empty], style: ['10'] },
    { text: nl, style: ['12'] },
    { text: 'Umowa o odpowiedzialności materialnej', style: ['right', 'bold', '12'] },
    { text: ['nr ', documentNumber ?? empty], style: ['right', 'bold', '10'] },
    { text: 'ZZ-N-02', style: ['right', 'bold', '10'] },
    { text: nl, style: ['10'] },
    {
      text: [
        'Zawarta w dniu ',
        documentDate ?? empty,
        ' r. w ',
        documentPlace ?? empty,
        ' pomiędzy:'
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: [
        employerName ?? empty,
        ' z siedzibą w ',
        employerHQ ?? empty,
        ', wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego przez ',
        employerEntered ?? empty,
        ', pod numerem KRS ',
        employerKRS ?? empty,
        ', REGON ',
        employerREGON ?? empty,
        ', NIP ',
        employerNIP ?? empty,
        '.'
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: [
        'zwana dalej ',
        { text: 'Zleceniodawcą', style: ['bold'] }
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: [
        'reprezentowaną przez ',
        employerRep ?? empty
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: 'a', style: ['11'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: [
        employeeName ?? empty,
        ' (imię i nazwisko), legitymującym się dowodem osobistym seria ',
        employeeCardSeries ?? empty,
        ' nr ',
        employeeCardNumber ?? empty,
        ' zamieszkałym: adres: ',
        employeeAddress ?? empty,
        ' kod pocztowy ',
        employeePostalcode ?? empty,
        ' PESEL ',
        employeePesel ?? empty,
        ', nr prawa jazdy ',
        employeeLicense ?? empty,
        ', data ważności prawa jazdy',
        employeeLicenseValid ?? empty,
        ','
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: [
        'zwanym dalej ',
        { text: 'Zleceniobiorcą', style: ['bold'] },
        '.'
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 1', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: [
        'Strony oświadczają, iż w dniu ',
        n1date ?? empty,
        ' zawarły umowę zlecenia, zgodnie, z którą Zleceniobiorca zobowiązał się do wykonania na rzecz Zleceniodawcy przedmiotu zlecenia w zakresie ',
        n1scope ?? empty,
        '. Na podstawie niniejszej umowy Zleceniodawca powierza Zleceniobiorcy w celu wykonywania obowiązków wynikających z zawartej umowy zlecenia samochód o numerach i parametrach oraz wraz z wyposażeniem, które zostały wskazane w Protokole zdawczo- odbiorczym stanowiącym załącznik do niniejszej Umowy, a Zleceniobiorca przejmuje pełną odpowiedzialność materialną za stan samochodu oraz jego kompletność, w momencie podpisania ',
        { text: 'Protokołu zdawczo-odbiorczego', style: ['bold'] },
        ', stanowiącego załącznik do niniejszej umowy.'
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 2', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Zleceniobiorca oświadcza, że posiada ważne prawo jazdy i jednocześnie deklaruje, że jego aktualny stan zdrowia nie ogranicza zarówno jego kompetencji jak i zdolności do kierowania pojazdami mechanicznymi. W przypadku wymiany prawa jazdy Zleceniobiorca zobowiązany jest w terminie do 2 tygodni powiadomić Administratora Floty o jego wymianie.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 3', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Dowód rejestracyjny przechowywany jest w centrali, w przypadku wyjazdu pojazdu za granicę, należy zgłosić się po dowód oraz upoważnienie do wyjazdu za granicę z wyprzedzeniem, do co najmniej 2-3 tygodni wcześniej, niż planowany wyjazd. Prowadzenie pojazdu bez posiadania przy sobie aktualnego prawa jazdy jest zakazane. Zleceniodawca przekazuje Zleceniobiorcy kartę paliwową, którą Zleceniobiorca zobowiązuje się wykorzystywać zgodnie z instrukcjami udzielonymi przez Zleceniodawcę oraz Administratora Floty.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 4', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Zleceniobiorca zobowiązuje się odpowiadać za stan techniczny i zabezpieczenie powierzonego mu pojazdu oraz przejmuje pełną odpowiedzialność za jego prawidłową i zgodną z Prawem o ruchu drogowym eksploatację.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: 'Zleceniobiorca użytkujący samochód: odpowiada za wszelkie wykroczenia drogowe i ich skutki, a nadto odpowiada za wszelkie szkody wynikłe wskutek korzystania z samochodu.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: 'W przypadku kolizji drogowej, kiedy szkoda zostanie pokryta z OC sprawcy (osoby trzeciej), użytkownik zobowiązany jest wezwać Policję. Niewywiązanie się z tego obowiązku, powoduje potrącenie z wynagrodzenia Zleceniobiorcy kary w wysokości 100 zł, na co Zleceniobiorca wyraża zgodę.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: 'W przypadku pojazdów leasingowanych, Zleceniobiorca ukarany mandatem dodatkowo zostaje obciążony kwotą 50 zł, naliczaną przez firmę leasingującą pojazd za udzielanie odpowiedzi organom ścigania w związku np. z wykroczeniami drogowymi',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: 'W przypadku, gdy uszkodzenie pojazdu nastąpi z winy Zleceniobiorcy, a koszty naprawy uregulowane będą z polisy AC Zleceniodawcy, z wynagrodzenia Zleceniobiorcy zostanie potrącona kara w wysokości połowy kwoty netto naprawy, dla napraw o wartości do 500,00 zł, oraz w wysokości 250,00 zł dla napraw o wartości przekraczającej 500,00 zł netto powyższych potrąceń z przysługującego mu wynagrodzenia. Zleceniobiorca oświadcza, że wyraża zgodę na dokonywanie powyższych potrąceń z przysługującego mu wynagrodzenia.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 5', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'W samochodzie obowiązuje bezwzględny zakaz palenia tytoniu przez Zleceniobiorcę jak i przebywających w jego wnętrzu pasażerów. W przypadku wykrycia oznak palenia z wynagrodzenia Zleceniobiorcy zostanie potrącona kara w wysokości 250,00 zł. Zleceniobiorca oświadcza, że wyraża zgodę na dokonywanie powyższych potrąceń z przysługującego mu wynagrodzenia.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 6', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Zleceniobiorca zobowiązuje się do wykonywania poleceń i zaleceń wydawanych przez Administratora Floty Samochodowej, związanych bezpośrednio z eksploatacją pojazdu oraz do poddawania się kwartalnym przeglądom estetyki samochodów, prowadzonych przez Menedżerów Regionalnych. Wyniki przeglądu obejmującego m.in. estetykę samochodu oraz stan zabezpieczeń (alarm, blokady, oznakowanie elementów) zgłaszane są do Administratora Floty.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: 'Dla użytkowników wzorowo wywiązujących się z obowiązków związanych z eksploatacją samochodu, przewidziana jest roczna nagroda w wysokości 250 zł. Nagroda przysługuje osobie, która w ciągu kolejnych 12 miesięcy od daty podpisania umowy, z własnej winy nie spowodowała uszkodzenia pojazdu i uzyskała maksymalną punktację podczas czterech kolejnych kwartalnych ocen stanu pojazdu. Nagroda wypłacana jest na koniec miesiąca następującego po miesiącu, w którym upłynął kolejny pełny rok kalendarzowy od daty zawarcia umowy, przy założeniu spełnienia warunków, o których mowa powyżej.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 7', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Zleceniobiorca posiadając świadomość, że pojazd został mu przydzielony w celu wykonania przedmiotu umowy zlecenia, o której mowa w §1 niniejszej umowy, zobowiązuje się używać go wyłącznie do tych celów.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: 'Zleceniobiorca, stale użytkujący samochód może używać samochód do celów niezwiązanych z realizacją przedmiotu umowy zlecenia wyłącznie po uzyskaniu pisemnej zgody Zleceniodawcy. Uzyskanie zgody jest równoznaczne z akceptacją zasad związanych z użyczeniem pojazdu do celów innych niż służbowe. Za korzystanie z samochodu dla celów innych niż związane z przedmiotem zlecenia, Zleceniobiorca będzie wnosił opłatę na rzecz Zleceniodawcy, równą liczbie przejechanych kilometrów przemnożonej przez stawkę ryczałtową ustalaną przez Ministra Transportu dla wykorzystujących samochód prywatny dla celów służbowych. Dopuszcza się jednak również inne rozwiązania.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 8', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Zleceniobiorca zobowiązuje się nie wprowadzać jakiegokolwiek dodatkowego wyposażenia, niezgodnego ze standardem danego pojazdu bez zgody Administratora Floty. Dopuszcza się, że Administrator Floty Samochodowej może na powyższe wyrazić zgodę. Przy zmianie użytkownika lub wycofaniu pojazdu z eksploatacji ruchome elementy dodatkowego wyposażenia zabiera dotychczasowy użytkownik, wymaga to jednak powiadomienia Administratora.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 9', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Zleceniobiorca zobowiązuje się przedstawiać na żądanie Zleceniodawcy wykaz jazd dokonanych na przestrzeni miesiąca rozliczeniowego oraz uzasadnienia ich celowości, jakkolwiek Zleceniodawca nie określa z góry miesięcznych limitów przejeżdżanych kilometrów.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 10', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Zleceniobiorca zobowiązuje się systematycznie prowadzić Kartę pojazdu i okazywać ją na żądanie Zleceniodawcy i Administratora Floty.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 11', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: [
        'Zleceniobiorca zobowiązuje się nie przewozić osób trzecich w samochodzie służbowym z wyłączeniem pracowników ',
        n11t1 ?? empty,
        ' oraz osób świadczących na rzecz ',
        n11t2 ?? empty,
        ' na podstawie stosunku prawnego innego niż stosunek pracy.'
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 12', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: [
        'Przedmiotowy samochód może zostać oddany do dyspozycji innego lub innych pracowników firmy lub osób świadczących na rzecz ',
        n12t1 ?? empty,
        ' czynności na podstawie stosunku prawnego innego niż stosunek pracy na polecenie Zleceniodawcy. Dyspozycja ta nosi nazwę użytkowania czasowego. Dotyczy to zwłaszcza okresu niewykonywania przez Zleceniobiorcę przedmiotu zlecenia, wtedy samochód może zostać przekazany do eksploatacji innej osobie na czas określony przez Menedżera Regionalnego.'
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: 'O przekazaniu pojazdu, Administrator Floty informowany jest mailem.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: 'Przekazanie odbywa się na podstawie protokołu zdawczo – odbiorczego, a czasowego użytkownika obowiązują w całej rozciągłości postanowienia niniejszej umowy. Używanie tego samochodu przez inne osoby odbywa się po powiadomieniu Zleceniobiorcy, któremu został on wcześniej powierzony protokołem zdawczo-odbiorczym. Przed ponownym przejęciem z użytkowania czasowego, Zleceniobiorca ma prawo zażądać ponownego formalnego przekazania. W takim przypadku Zleceniobiorca nie będzie odpowiadać za szkody, jakie powstały w czasie, gdy samochód pozostawał w użytkowaniu innej osoby lub osób.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: 'Dopuszcza się rezygnację z przygotowania protokołu zdawczo-odbiorczego dla użyczenia czasowego, jeżeli czas użytkowania auta przez inną osobę nie przekroczy 1 dnia.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 13', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Zleceniobiorca zobowiązuje się:',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      ul: [
        { text: 'przestrzegać terminów ważności ubezpieczeń,' },
        { text: ['przestrzegać terminów badania technicznego pojazdu i dokonywać ww. przeglądy w uprawnionych stacjach na koszt ', n13t1 ?? empty, ','] },
        { text: 'kontrolować stan zabezpieczeń samochodu (alarm, blokady, oznakowanie elementów),' },
        { text: 'parkować, jeśli istnieją ku temu  warunki, wyłącznie w miejscach strzeżonych' },
        { text: 'w przypadku stwierdzenia jakichkolwiek usterek lub nieprawidłowości w działaniu pojazdu przekazywać samochód do autoryzowanej stacji obsługi wskazanej przez Administratora Floty,' },
        { text: 'w przypadku wystąpienia szkody, jest zobowiązany do poinformowania Zleceniodawcy oraz w formie pisemnej (mail) Administratora Floty. W zakresie działań z likwidacją szkody, Zleceniobiorca zobowiązany jest postępować zgodnie z wytycznymi Administratora Floty.' },
        { text: 'dokonywać miesięcznych rozliczeń z tytułu kosztów eksploatacji najpóźniej do 5 dnia następnego miesiąca po miesiącu, którego dotyczy rozliczenie.' },
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 14', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Zleceniobiorca zgadza się przy zdaniu samochodu pokryć zawinione przez niego straty wynikające z różnicy między stanem początkowym a stanem końcowym uwidocznionymi w protokołach zdawczym i odbiorczym, wyliczone z uwzględnieniem współczynnika amortyzacji.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 15', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: [
        'Umowa niniejsza obowiązuje od ',
        n15t1 ?? empty,
        ' r. i nie traci swojej ważności w przypadku podpisania przez Zleceniobiorcę kolejnych umów zlecenia pod warunkiem zachowania ich ciągłości. Umowa może być rozwiązana przez obie strony z zachowaniem jednotygodniowego okresu wypowiedzenia.'
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 16', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: [
        'Umowę sporządzono w dwóch jednobrzmiących egzemplarzach, po jednym dla każdej ze stron. Strony zgodnie oświadczają, że w przypadku gdyby szkoda poniesiona przez ',
        n16t1 ?? empty,
        ' przekroczyła wartość określonych w umowie kar, ',
        n16t2 ?? empty,
        ' uprawniona jest do dochodzenia od Zleceniobiorcy odszkodowania w pełnej wysokości, a Zleceniobiorca wyraża zgodę na potrącenie kwoty szkody z przysługującego mu wynagrodzenia. W celu rozwiązania wątpliwości Zleceniobiorca oświadcza nadto, że wyraża zgodę na potrącenie z wynagrodzenia wszelkich należności wynikających z niniejszej umowy, które wynikają z naruszenia przez niego postanowień umowy.'
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: nl, style: ['11'], margin: [0, 5], lineHeight: 1.2 },
    { text: nl, style: ['11'], margin: [0, 5], lineHeight: 1.2 },
    {
      columns: [
        {
          stack: [
            empty,
            { text: 'Zleceniodawca', margin: [0, 5] }
          ]
        },
        {
          stack: [
            empty,
            { text: 'Zleceniobiorca', margin: [0, 5] }
          ]
        }
      ],
      columnGap: 40,
      style: ['11', 'center'],
      margin: [0, 5],
      lineHeight: 1.2
    }
  ],
  header: [
    {
      text: ['Obowiązuje od: ', validFrom ?? empty],
      style: ['10'],
      lineHeight: 1.2,
      margin: [40, 40]
    }
  ],
  footer: (currentPage, pageCount) => [
    {
      columns: [
        '',
        {
          text: `Strona ${currentPage} z ${pageCount}`,
          style: ['8', 'right'],
          lineHeight: 1.2,
          margin: [40, 40]
        }
      ],
      columnGap: 10
    }
  ],
  defaultStyle: documentDefaultStyle,
  pageMargins: documentMargins,
  styles: documentStyles,
  images: documentImages,
})
