





















import { defineComponent, nextTick, ref, watch } from "@vue/composition-api";
import Editor from "@/components/molecules/m-protocol-editor.vue";

export default defineComponent({
  components: {
    MProtocolEditor: Editor,
  },
  emits: ["save"],
  setup(_, { emit }) {
    const editor = ref<InstanceType<typeof Editor>>();

    const model = ref(false);

    const preview = ref(false);
    const previewDisabled = ref(false);

    watch(preview, () => {
      previewDisabled.value = true;
      setTimeout(() => (previewDisabled.value = false), 1000);
    });

    const save = async () => {
      emit("save", await editor.value?.getProtocols());
      nextTick(() => {
        model.value = false;
      });
    };

    return { editor, model, preview, previewDisabled, save };
  },
});
