import type { TDocumentDefinitions } from 'pdfmake/interfaces'
import { DocumentField } from '@/use/document';
import { documentDefaultStyle, documentImages, documentMargins, documentStyles, empty, nl } from '@/helper/document';
import moment from 'moment';

export interface ProtocolVariables {
  validFrom?: string;

  place?: string;
  date?: string;
  time?: string;

  companyName?: string;
  companyAddress?: string;
  companyNumber?: string;

  giverName?: string;
  giverNumber?: string;

  receiverName?: string;
  receiverNumber?: string;

  vehicleType?: 'car' | 'truck';
  vehicleBrand?: string;
  vehicleRegistration?: string;
  vehicleYear?: string;
  vehicleVin?: string;
  vehicleNumber?: string;
  vehicleInspection?: string;
  vehicleInsurance?: string;
  vehicleMileage?: string;
  vehicleFuel?: string;
  vehicleLights?: string;
  vehicleTires?: 'summer' | 'winter';
  vehicleTireSize?: string;
  vehicleTireStorage?: string;

  handoverRegistration?: boolean;
  handoverInsurance?: boolean;
  handoverFuelCard?: boolean;
  handoverServiceBook?: boolean;
  handoverManual?: boolean;
  handoverRadio?: boolean;
  handoverMats?: boolean;
  handoverTriangle?: boolean;
  handoverJack?: boolean;
  handoverSpare?: boolean;
  handoverTools?: boolean;
  handoverCleanOutside?: boolean;
  handoverCleanInside?: boolean;
  handoverKeys?: number;
  handoverExtinguisher?: string;

  damage?: string;
  comments?: string;

  [key: string]: any;
}

export const fields: Array<DocumentField> = [
  // Header
  {
    type: "divider",
    name: "headerDivider",
    attrs: { label: "Nagłówek" },
  },
  {
    type: "date",
    name: "validFrom",
    attrs: { label: "Obowiązuje od" },
  },
  {
    type: "text",
    name: "place",
    attrs: { label: "Miejscowość" },
  },
  {
    type: "date",
    name: "date",
    attrs: { label: "Dnia" },
  },
  {
    type: "time",
    name: "time",
    attrs: { label: "Godz." },
  },
  // Company
  {
    type: "divider",
    name: "companyDivider",
    attrs: { label: "Firma" },
  },
  {
    type: "text",
    name: "companyName",
    attrs: { label: "Nazwa firmy" },
  },
  {
    type: "text",
    name: "companyAddress",
    attrs: { label: "Adres firmy" },
  },
  {
    type: "text",
    name: "companyNumber",
    attrs: { label: "Numer firmy" },
  },
  // Users
  {
    type: "divider",
    name: "userDivider",
    attrs: { label: "Użytkownicy" },
  },
  {
    type: "text",
    name: "giverName",
    attrs: { label: "Imię i nazwisko osoby zdającej" },
  },
  {
    type: "text",
    name: "giverNumber",
    attrs: { label: "Numer umowy osoby zdającej" },
  },
  {
    type: "text",
    name: "receiverName",
    attrs: { label: "Imię i nazwisko osoby odbierającej" },
  },
  {
    type: "text",
    name: "receiverNumber",
    attrs: { label: "Numer umowy osoby odbierającej" },
  },
  // Vehicle
  {
    type: "divider",
    name: "vehicleDivider",
    attrs: { label: "Pojazd" },
  },
  {
    type: "select",
    name: "vehicleType",
    attrs: { label: "Rodzaj pojazdu", items: [{ text: 'osobowy', value: 'car', }, { text: 'ciężarowy', value: 'truck' }] },
  },
  {
    type: "text",
    name: "vehicleBrand",
    attrs: { label: "Marka samochodu" },
  },
  {
    type: "text",
    name: "vehicleRegistration",
    attrs: { label: "Numer rejestracyjny" },
  },
  {
    type: "text",
    name: "vehicleYear",
    attrs: { label: "Rok produkcji" },
  },
  {
    type: "text",
    name: "vehicleVin",
    attrs: { label: "Numer pojazdu VIN" },
  },
  {
    type: "text",
    name: "vehicleNumber",
    attrs: { label: "Seria i numer dowodu rejestracyjnego" },
  },
  {
    type: "text",
    name: "vehicleInspection",
    attrs: { label: "Data badania technicznego" },
  },
  {
    type: "text",
    name: "vehicleInsurance",
    attrs: { label: "Ważność ubezpieczenia" },
  },
  {
    type: "text",
    name: "vehicleMileage",
    attrs: { label: "Stan licznika" },
  },
  {
    type: "text",
    name: "vehicleFuel",
    attrs: { label: "Stan paliwa" },
  },
  {
    type: "textarea",
    name: "vehicleLights",
    attrs: { label: "Kontrolki w samochodzie" },
  },
  {
    type: "select",
    name: "vehicleTires",
    attrs: { label: "Opony", items: [{ text: 'letnie', value: 'summer', }, { text: 'zimowe', value: 'winter' }] },
  },
  {
    type: "text",
    name: "vehicleTireSize",
    attrs: { label: "Marka, rozmiar" },
  },
  {
    type: "text",
    name: "vehicleTireStorage",
    attrs: { label: "Drugi komplet opon" },
  },
  // Handover
  {
    type: "divider",
    name: "handoverDivider",
    attrs: { label: "Przekazano" },
  },
  {
    type: "select",
    name: "handoverRegistration",
    attrs: { label: "Dowód rejestracyjny", items: [{ text: 'TAK', value: true, }, { text: 'NIE', value: false }] },
  },
  {
    type: "select",
    name: "handoverInsurance",
    attrs: { label: "Potwierdzenie zawarcia ubezpieczenia OC", items: [{ text: 'TAK', value: true, }, { text: 'NIE', value: false }] },
  },
  {
    type: "select",
    name: "handoverFuelCard",
    attrs: { label: "Karta paliwowa", items: [{ text: 'TAK', value: true, }, { text: 'NIE', value: false }] },
  },
  {
    type: "select",
    name: "handoverServiceBook",
    attrs: { label: "Książka serwisowa", items: [{ text: 'TAK', value: true, }, { text: 'NIE', value: false }] },
  },
  {
    type: "select",
    name: "handoverManual",
    attrs: { label: "Instrukcja obsługi auta", items: [{ text: 'TAK', value: true, }, { text: 'NIE', value: false }] },
  },
  {
    type: "select",
    name: "handoverRadio",
    attrs: { label: "Radio", items: [{ text: 'TAK', value: true, }, { text: 'NIE', value: false }] },
  },
  {
    type: "select",
    name: "handoverMats",
    attrs: { label: "Dywaniki", items: [{ text: 'TAK', value: true, }, { text: 'NIE', value: false }] },
  },
  {
    type: "select",
    name: "handoverTriangle",
    attrs: { label: "Twójkąt ostrzegawczy", items: [{ text: 'TAK', value: true, }, { text: 'NIE', value: false }] },
  },
  {
    type: "select",
    name: "handoverJack",
    attrs: { label: "Podnośnik", items: [{ text: 'TAK', value: true, }, { text: 'NIE', value: false }] },
  },
  {
    type: "select",
    name: "handoverSpare",
    attrs: { label: "Koło zapasowe", items: [{ text: 'TAK', value: true, }, { text: 'NIE', value: false }] },
  },
  {
    type: "select",
    name: "handoverTools",
    attrs: { label: "Zestaw narzędzi", items: [{ text: 'TAK', value: true, }, { text: 'NIE', value: false }] },
  },
  {
    type: "select",
    name: "handoverCleanOutside",
    attrs: { label: "Samochód czysty na zewnątrz", items: [{ text: 'TAK', value: true, }, { text: 'NIE', value: false }] },
  },
  {
    type: "select",
    name: "handoverCleanInside",
    attrs: { label: "Samochód czysty wewnątrz", items: [{ text: 'TAK', value: true, }, { text: 'NIE', value: false }] },
  },
  {
    type: "number",
    name: "handoverKeys",
    attrs: { label: "Kluczyk samochodowy (ilość)", min: 0 },
  },
  {
    type: "date",
    name: "handoverExtinguisher",
    attrs: { label: "Gaśnica (brak lub jeśli jest data ważności)" },
  },
  // Condition
  {
    type: "divider",
    name: "conditionDivider",
    attrs: { label: "Stan techniczny" },
  },
  {
    type: "textarea",
    name: "damage",
    attrs: { label: "Szkody" },
  },
  {
    type: "textarea",
    name: "comments",
    attrs: { label: "Uwagi do stanu technicznego" },
  },
]

export const variablesToProtocol = (variables: { [key: string]: any }) => {
  const lastInspection = variables.vehicle?.services?.find((service: any) => service.type === 'technical_inspection')
  const lastTireChange = variables.vehicle?.services?.find((service: any) => service.type === 'tire_change')
  const lastInsurance = variables.vehicle?.insurances?.find((insurance: any) => !!insurance.endDate)

  const tireType = lastTireChange?.tireType === 'summer' ? 'summer' : lastTireChange?.tireType === 'winter' ? 'winter' : null
  const tireSize = lastTireChange?.tireSize ?? null
  const tireStorage = lastTireChange?.tireStoragePlace ?? null

  return {
    validFrom: '2018-11-23',

    place: variables.company?.city ?? null,

    date:
      variables.type === "vehicleHandover"
        ? variables.handoverDate
        : variables.returnDate,

    time: moment().format("HH:mm"),

    companyName: variables.company?.name ?? null,

    companyAddress: variables.company
      ? `${variables.company.address}, ${variables.company.postcode} ${variables.company.city}`
      : null,

    companyNumber: variables.company
      ? variables.company.Regon
        ? `REGON ${variables.company.Regon}`
        : variables.company.NIP
          ? `NIP ${variables.company.NIP}`
          : variables.company.KRS
            ? `KRS ${variables.company.KRS}`
            : null
      : null,

    giverName:
      variables.type === "vehicleReturn"
        ? `${variables.user.firstName} ${variables.user.lastName}`
        : null,

    receiverName:
      variables.type === "vehicleHandover"
        ? `${variables.user.firstName} ${variables.user.lastName}`
        : null,

    vehicleType: "car",
    vehicleBrand: variables.vehicle?.details?.vehicleBrand ?? null,
    vehicleRegistration:
      variables.vehicle?.details?.registrationNumber ?? null,
    vehicleYear: variables.vehicle?.details?.productionYear ?? null,
    vehicleVin: variables.vehicle?.details?.vinNumber ?? null,
    vehicleNumber:
      variables.vehicle?.details?.approvalCertificateNumber ?? null,

    vehicleInspection: lastInspection?.nextServiceDate ? moment(lastInspection.nextServiceDate).format('YYYY-MM-DD') : null,
    vehicleInsurance: lastInsurance?.endDate ? moment(lastInsurance.endDate).format('YYYY-MM-DD') : null,

    vehicleMileage: variables.vehicle?.mileages && variables.vehicle.mileages[0] ? `${variables.vehicle.mileages[0].mileage ?? 0} km` : null,
    vehicleFuel: null,
    vehicleLights: null,

    vehicleTires: tireType ?? null,
    vehicleTireSize: tireSize ?? null,
    vehicleTireStorage: tireStorage ?? null,
  }
}

export default ({
  validFrom,
  place,
  date,
  time,
  companyName,
  companyAddress,
  companyNumber,
  giverName,
  giverNumber,
  receiverName,
  receiverNumber,
  vehicleType,
  vehicleBrand,
  vehicleRegistration,
  vehicleYear,
  vehicleVin,
  vehicleNumber,
  vehicleInspection,
  vehicleInsurance,
  vehicleMileage,
  vehicleFuel,
  vehicleLights,
  vehicleTires,
  vehicleTireSize,
  vehicleTireStorage,
  handoverRegistration,
  handoverInsurance,
  handoverFuelCard,
  handoverServiceBook,
  handoverManual,
  handoverRadio,
  handoverMats,
  handoverTriangle,
  handoverJack,
  handoverSpare,
  handoverTools,
  handoverCleanOutside,
  handoverCleanInside,
  handoverKeys,
  handoverExtinguisher,
  damage,
  comments
}: ProtocolVariables): TDocumentDefinitions => ({
  content: [
    { text: [place ?? empty, ', dnia ', date ?? empty, ' godz. ', time ?? empty], style: ['right'] },
    { text: [companyName ?? empty, nl, companyAddress ?? empty, nl, companyNumber ?? empty], lineHeight: 1.25 },
    { text: nl, lineHeight: 1 },
    {
      table: {
        widths: [100, '*', '*'],
        headerRows: 1,
        body: [
          [
            { text: '', style: ['fill-dark', 'text-white'] },
            { text: 'Dane osoby zdającej samochód służbowy', style: ['fill-dark', 'text-white'] },
            { text: 'Dane osoby odbierającej samochód służbowy', style: ['fill-dark', 'text-white'] }
          ],
          [{ text: 'Imię i nazwisko', style: ['fill-light'] }, giverName ?? '', receiverName ?? ''],
          [{ text: 'Numer umowy (wypełnia Administrator Floty)', style: ['fill-light'] }, giverNumber ?? '', receiverNumber ?? '']
        ],
        dontBreakRows: true
      },
      lineHeight: 1,
      style: ['8', 'bold']
    },
    { text: nl, lineHeight: 1 },
    { text: 'Protokół zdawczo-odbiorczy samochodu służbowego', style: ['right', '12', 'bold'] },
    { text: 'ZZ-N-04', style: ['right', 'bold'] },
    { text: 'Niniejszym zdaję z eksploatacji / przyjmuję do eksploatacji poniżej określony samochód eksploatowany w oparciu o Umowę  przydzielenia samochodu  służbowego do eksploatacji przez pracownika do celów związanych z wykonywaniem czynności służbowych.', style: ['8', 'italics'], lineHeight: 1 },
    { text: nl, lineHeight: 1 },
    {
      columns: [
        {
          stack: [
            {
              table: {
                widths: [100, '*'],
                body: [
                  [{ text: 'Rodzaj pojazdu', style: ['bold', 'fill-light'] }, { text: [{ text: 'osobowy', style: vehicleType === 'truck' ? ['lineThrough'] : [] }, '  /  ', { text: 'ciężarowy', style: vehicleType === 'car' ? ['lineThrough'] : [] }] }],
                  [{ text: 'Marka samochodu (D1)', style: ['bold', 'fill-light'] }, vehicleBrand ?? ''],
                  [{ text: 'Numer rejestracyjny (A)', style: ['bold', 'fill-light'] }, vehicleRegistration ?? ''],
                  [{ text: 'Rok produkcji', style: ['bold', 'fill-light'] }, vehicleYear ?? ''],
                  [{ text: 'Numer pojazdu VIN (E)', style: ['bold', 'fill-light'] }, vehicleVin ?? ''],
                  [{ text: 'Seria i nr dowodu rejestracyjnego', style: ['bold', 'fill-light'] }, vehicleNumber ?? ''],
                  [{ text: 'Data badania technicznego', style: ['bold', 'fill-light'] }, vehicleInspection ?? ''],
                  [{ text: 'Ważność ubezpieczenia', style: ['bold', 'fill-light'] }, vehicleInsurance ?? ''],
                  [{ text: 'Stan licznika', style: ['bold', 'fill-light'] }, vehicleMileage ?? ''],
                  [{ text: 'Stan paliwa', style: ['bold', 'fill-light'] }, vehicleFuel ?? ''],
                  [{ text: [{ text: 'Kontrolki w samochodzie', style: ['bold'] }, nl, '(informacja o pomarańczowych i czerwonych kontrolkach)'], style: ['fill-light'] }, vehicleLights ?? ''],
                  [{ text: 'OPONY', style: ['bold', 'fill-light'] }, { text: [{ text: 'letnie', style: vehicleTires === 'winter' ? ['lineThrough'] : [] }, '  /  ', { text: 'zimowe', style: vehicleTires === 'summer' ? ['lineThrough'] : [] }] }],
                  [{ text: [{ text: 'Marka, rozmiar', style: ['bold'] }, nl, '(obowiązkowe dla samochodów Leasingowych)'], style: ['fill-light'] }, vehicleTireSize ?? ''],
                  [{ text: [{ text: 'Drugi komplet opon', style: ['bold'] }, nl, '(gdzie?)'], style: ['fill-light'] }, vehicleTireStorage ?? ''],
                ],
                dontBreakRows: true
              },
              style: ['8'],
              lineHeight: 1
            },
            { text: nl, lineHeight: 1 },
            {
              text: [
                'Ponadto raz jeszcze oświadczam, że znam i akceptuję zasady sformułowane w ',
                { text: 'Umowie przydzielenia samochodu służbowego do eksploatacji przez pracownika do celów związanych z wykonywaniem czynności służbowych', style: ['bold'] }
              ],
              style: ['8', 'italics'],
              lineHeight: 1
            },
            { text: nl, lineHeight: 1 },
            {
              table: {
                widths: ['*'],
                body: [
                  [[nl, nl, 'podpis osoby odbierającej samochód']]
                ],
                dontBreakRows: true
              },
              lineHeight: 1,
              style: ['7'],
            },
            { text: nl, lineHeight: 1 },
            {
              table: {
                widths: ['*'],
                body: [
                  [[nl, nl, 'podpis osoby zdającej samochód']]
                ],
                dontBreakRows: true
              },
              lineHeight: 1,
              style: ['7'],
            },
            { text: nl, lineHeight: 1 },
            {
              table: {
                widths: ['*', 20, 20],
                body: [
                  [
                    { text: 'PRZEKAZANO', style: ['left', 'bold', 'fill-dark', 'text-white'] },
                    { text: 'TAK', style: ['bold', 'fill-dark', 'text-white'] },
                    { text: 'NIE', style: ['bold', 'fill-dark', 'text-white'] }
                  ],
                  [{ text: 'Dowód rejestracyjny', style: ['fill-light', 'left'] }, handoverRegistration === true ? '×' : '', handoverRegistration === false ? '×' : ''],
                  [{ text: 'Potwierdzenie zawarcia ubezpieczenia OC', style: ['fill-light', 'left'] }, handoverInsurance === true ? '×' : '', handoverInsurance === false ? '×' : ''],
                  [{ text: 'Karta paliwowa', style: ['fill-light', 'left'] }, handoverFuelCard === true ? '×' : '', handoverFuelCard === false ? '×' : ''],
                ],
                dontBreakRows: true
              },
              lineHeight: 1,
              style: ['8', 'center']
            }
          ]
        },
        {
          stack: [
            {
              table: {
                widths: ['*', 20, 20],
                body: [
                  [{ text: 'Książka serwisowa', style: ['fill-light', 'left'] }, handoverServiceBook === true ? '×' : '', handoverServiceBook === false ? '×' : ''],
                  [{ text: 'Instrukcja obsługi auta', style: ['fill-light', 'left'] }, handoverManual === true ? '×' : '', handoverManual === false ? '×' : ''],
                  [{ text: 'Radio', style: ['fill-light', 'left'] }, handoverRadio === true ? '×' : '', handoverRadio === false ? '×' : ''],
                  [{ text: 'Dywaniki', style: ['fill-light', 'left'] }, handoverMats === true ? '×' : '', handoverMats === false ? '×' : ''],
                  [{ text: 'Trójkąt ostrzegawczy', style: ['fill-light', 'left'] }, handoverTriangle === true ? '×' : '', handoverTriangle === false ? '×' : ''],
                  [{ text: 'Podnośnik', style: ['fill-light', 'left'] }, handoverJack === true ? '×' : '', handoverJack === false ? '×' : ''],
                  [{ text: 'Koło zapasowe', style: ['fill-light', 'left'] }, handoverSpare === true ? '×' : '', handoverSpare === false ? '×' : ''],
                  [{ text: 'Zestaw narzędzi', style: ['fill-light', 'left'] }, handoverTools === true ? '×' : '', handoverTools === false ? '×' : ''],
                  [{ text: 'Samochód czysty na zewnątrz', style: ['fill-light', 'left'] }, handoverCleanOutside === true ? '×' : '', handoverCleanOutside === false ? '×' : ''],
                  [{ text: 'Samochód czysty wewnątrz', style: ['fill-light', 'left'] }, handoverCleanInside === true ? '×' : '', handoverCleanInside === false ? '×' : ''],
                  [{ text: 'Kluczyk samochodowy (ilość?)', style: ['fill-light', 'left'] }, { text: handoverKeys ?? '', colSpan: 2 }],
                  [{ text: 'Gaśnica (brak lub jeśli jest data ważności)', style: ['fill-light', 'left'] }, { text: handoverExtinguisher ?? '', colSpan: 2 }],
                ],
                dontBreakRows: true
              },
              lineHeight: 1,
              style: ['8', 'center']
            },
            { text: nl, lineHeight: 1 },
            {
              table: {
                widths: ['*'],
                body: [
                  [{ text: 'Charakterystyka stanu nadwozia i stanu technicznego', style: ['fill-dark', 'text-white', 'bold'] }],
                  [[nl, { image: 'vehicle', width: 215, style: ['center'] }, nl]],
                  [['Szkody:', ...damage ? [damage, nl] : [nl, nl, nl, nl], 'Uwagi do stanu technicznego poprzedniego użytkownika:', ...comments ? [comments] : [nl, nl, nl, nl]]]
                ],
                dontBreakRows: true
              },
              lineHeight: 1,
              style: ['8']
            },
            { text: nl, lineHeight: 1 },
            {
              table: {
                widths: ['*'],
                body: [
                  [[nl, nl, nl, 'podpis przełożonego lub osoby upoważnionej do przekazania  samochodu']]
                ],
                dontBreakRows: true
              },
              lineHeight: 1,
              style: ['7']
            },
          ]
        }
      ],
      columnGap: 10
    },
  ],
  header: {
    stack: [
      { text: 'Załącznik do umowy przydzielenia samochodu służbowego do eksploatacji przez pracownika do celów związanych z wykonywaniem czynności służbowych', style: ['8'] },
      nl,
      `Obowiązuje od: ${validFrom ?? empty}`
    ],
    lineHeight: 1,
    margin: [40, 20, 40, 20]
  },
  footer: [
    {
      text: '* niepotrzebne skreślić',
      style: ['8'],
      margin: [40, 20, 40, 20]
    }
  ],
  defaultStyle: documentDefaultStyle,
  pageMargins: documentMargins,
  styles: documentStyles,
  images: documentImages
})
