






















































import { variablesToAssignment } from "@/assets/templates/pdf/assignment";
import { variablesToDamage } from "@/assets/templates/pdf/damage";
import { variablesToProtocol } from "@/assets/templates/pdf/protocol";
import { variablesToResponsibility } from "@/assets/templates/pdf/responsibility";
import useDocument, { DocumentType } from "@/use/document";
import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import { useVModel } from "@vueuse/core";
import { useDebounceFn, watchDebounced } from "@vueuse/shared";
import moment from "moment";

import pdf from "vue-pdf";

export default defineComponent({
  components: {
    pdf,
    MProtocolEditorField: () =>
      import("@/components/molecules/m-protocol-editor-field.vue"),
  },
  props: {
    variables: {
      type: Object as PropType<{ [key: string]: any }>,
      required: true,
    },
    preview: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  emits: ["save"],
  setup(props, { emit }) {
    const { createDocument, documnetEditorFields, blobToFile } = useDocument();

    const loading = ref(true);
    const loadingDebounced = ref(true);

    const isPreview = useVModel(props, "preview", emit, {
      eventName: "update:preview",
      defaultValue: false,
    });

    const model = ref<
      {
        [key in DocumentType]: { [key: string]: any };
      }
    >({
      [DocumentType.Protocol]: {},
      [DocumentType.Damage]: {},
      [DocumentType.Responsibility]: {},
      [DocumentType.Assignment]: {},
    });

    const parseVariablesToModel = () => {
      model.value[DocumentType.Protocol] = variablesToProtocol(props.variables);
      model.value[DocumentType.Damage] = variablesToDamage(props.variables);
      model.value[DocumentType.Responsibility] = variablesToResponsibility(
        props.variables
      );
      model.value[DocumentType.Assignment] = variablesToAssignment(
        props.variables
      );
    };

    onMounted(parseVariablesToModel);
    watch(() => props.variables, parseVariablesToModel, { deep: true });

    const documents: Array<{
      type: DocumentType;
      name: string;
    }> = [
      {
        type: DocumentType.Protocol,
        name: "Protokół zdawczo-odbiorczy samochodu służbowego",
      },
      {
        type: DocumentType.Damage,
        name:
          "Informacja o stanie szkód na samochodzie (dotyczy samochodów wynajmowanych)",
      },
      {
        type: DocumentType.Responsibility,
        name: "Umowa o odpowiedzialności materialnej",
      },
      {
        type: DocumentType.Assignment,
        name:
          "Umowa przydzielenia samochodu służbowego do eksploatacji przez Pracownika do celów związanych z wykonywaniem czynności służbowych",
      },
    ];

    const selectedTypes = ref<Array<DocumentType>>([
      DocumentType.Protocol,
      DocumentType.Damage,
      DocumentType.Responsibility,
      DocumentType.Assignment,
    ]);

    const isSelected = (type: DocumentType) =>
      selectedTypes.value.includes(type);

    const selectType = (type: DocumentType) =>
      isSelected(type) ? void 0 : selectedTypes.value.push(type);

    const removeType = (type: DocumentType) =>
      isSelected(type)
        ? selectedTypes.value.splice(selectedTypes.value.indexOf(type), 1)
        : void 0;

    const toggleType = (type: DocumentType) =>
      isSelected(type) ? removeType(type) : selectType(type);

    const currentType = ref(DocumentType.Protocol);

    const switchEditor = (type: DocumentType) => (currentType.value = type);

    const documentPageCount = ref<number>();
    const documentUri = ref<string>();

    const renderDocument = useDebounceFn(async () => {
      documentPageCount.value = undefined;
      documentUri.value = undefined;

      const file = await createDocument(
        currentType.value,
        model.value[currentType.value]
      );
      documentUri.value = URL.createObjectURL(file);
      const document = await pdf.createLoadingTask(documentUri.value).promise;
      documentPageCount.value = document.numPages;

      loading.value = false;
      setTimeout(() => {
        loadingDebounced.value = false;
      }, 1000);
    }, 500);

    const loadDocument = () => {
      loading.value = true;
      loadingDebounced.value = true;
      renderDocument();
    };

    watch(currentType, loadDocument);
    watchDebounced(model, loadDocument, {
      deep: true,
      immediate: true,
      debounce: 2000,
      maxWait: 10000,
    });

    const getProtocols = async (): Promise<File[]> => {
      const date = moment().format("YYYY-MM-DD");

      const userName = props.variables.user
        ? `${props.variables.user?.firstName}-${props.variables.user?.lastName}`
        : "X";

      const registration =
        props.variables.vehicle?.details?.registrationNumber?.replace(
          /[\W_]+/g,
          ""
        ) ?? "X";

      const prefix = `CF_${date}_${userName}_${registration}`;

      const documents: File[] = [];

      for (const type of selectedTypes.value) {
        const suffix = {
          [DocumentType.Protocol]: "Protokol",
          [DocumentType.Damage]: "Szkody",
          [DocumentType.Responsibility]: "Odpowiedzialnosc",
          [DocumentType.Assignment]: "Przydzielenie",
        }[type];

        documents.push(
          blobToFile(
            await createDocument(type, model.value[type]),
            `${prefix}_${suffix}.pdf`
          )
        );
      }

      return documents;
    };

    return {
      loading,
      loadingDebounced,
      isPreview,
      model,
      documnetEditorFields,
      documents,
      selectedTypes,
      isSelected,
      selectType,
      removeType,
      toggleType,
      currentType,
      switchEditor,
      documentPageCount,
      documentUri,
      getProtocols,
    };
  },
});
