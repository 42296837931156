import {
  documentDefaultStyle,
  documentImages,
  documentMargins,
  documentStyles,
  empty,
  nl,
} from "@/helper/document";
import { DocumentField } from "@/use/document";
import moment from "moment";
import { TDocumentDefinitions } from "pdfmake/interfaces";

export interface ResponsibilityVariables {
  [key: string]: any;
}

export const fields: Array<DocumentField> = [
  // Header
  {
    type: "divider",
    name: "headerDivider",
    attrs: { label: "Nagłówek" },
  },
  {
    type: "date",
    name: "validFrom",
    attrs: { label: "Obowiązuje od" },
  },
  {
    type: "text",
    name: "place",
    attrs: { label: "Miejscowość" },
  },
  {
    type: "date",
    name: "date",
    attrs: { label: "Dnia" },
  },
  {
    type: "time",
    name: "time",
    attrs: { label: "Godz." },
  },
  // Company
  {
    type: "divider",
    name: "companyDivider",
    attrs: { label: "Firma" },
  },
  {
    type: "text",
    name: "companyName",
    attrs: { label: "Nazwa firmy" },
  },
  {
    type: "text",
    name: "companyAddress",
    attrs: { label: "Adres firmy" },
  },
  {
    type: "text",
    name: "companyNumber",
    attrs: { label: "Numer firmy" },
  },
  // Document
  {
    type: "divider",
    name: "documentDivider",
    attrs: { label: "Dokument" },
  },
  {
    type: "text",
    name: "documentNumber",
    attrs: { label: "Numer umowy" },
  },
  {
    type: "date",
    name: "documentDate",
    attrs: { label: "Data zwarcia" },
  },
  {
    type: "text",
    name: "documentPlace",
    attrs: { label: "Miejscowość zawarcia" },
  },
  // Employer
  {
    type: "divider",
    name: "employerDivider",
    attrs: { label: "Firma" },
  },
  {
    type: "text",
    name: "employerName",
    attrs: { label: "Firma zawierająca" },
  },
  {
    type: "text",
    name: "employerHQ",
    attrs: { label: "...z siedzibą..." },
  },
  {
    type: "text",
    name: "employerEntered",
    attrs: { label: "...wpisaną przez..." },
  },
  {
    type: "text",
    name: "employerKRS",
    attrs: { label: "KRS" },
  },
  {
    type: "text",
    name: "employerREGON",
    attrs: { label: "REGON" },
  },
  {
    type: "text",
    name: "employerNIP",
    attrs: { label: "NIP" },
  },
  {
    type: "text",
    name: "employerRep",
    attrs: { label: "...reprezentowaną przez..." },
  },
  // Employee
  {
    type: "divider",
    name: "employeeDivider",
    attrs: { label: "Pracownik" },
  },
  {
    type: "text",
    name: "employeeName",
    attrs: { label: "Imię i nazwisko" },
  },
  {
    type: "text",
    name: "employeeCardSeries",
    attrs: { label: "Seria dowodu osobistego" },
  },
  {
    type: "text",
    name: "employeeCardNumber",
    attrs: { label: "Numer dowodu osobistego" },
  },
  {
    type: "text",
    name: "employeeAddress",
    attrs: { label: "Adres" },
  },
  {
    type: "text",
    name: "employeePostalcode",
    attrs: { label: "Kod pocztowy" },
  },
  {
    type: "text",
    name: "employeePesel",
    attrs: { label: "PESEL" },
  },
  {
    type: "text",
    name: "employeeLicense",
    attrs: { label: "Nr prawa jazdy" },
  },
  {
    type: "date",
    name: "employeeLicenseValid",
    attrs: { label: "Data ważności prawa jazdy" },
  },
  {
    type: "text",
    name: "employeeCompany",
    attrs: { label: "Zatrudniony w" },
  },
  {
    type: "text",
    name: "employeePosition",
    attrs: { label: "Stanowisko" },
  },
  // #11
  {
    type: "divider",
    name: "n11Divider",
    attrs: { label: "§ 11" },
  },
  {
    type: "text",
    name: "n11t1",
    attrs: { label: "Pracowników..." },
  },
  {
    type: "text",
    name: "n11t2",
    attrs: { label: "na rzecz..." },
  },
];

export const variablesToAssignment = (variables: { [key: string]: any }) => ({
  validFrom: '2019-01-02',

  place: variables.company?.city ?? null,

  date:
    variables.type === "vehicleHandover"
      ? variables.handoverDate
      : variables.returnDate,

  time: moment().format("HH:mm"),

  companyName: variables.company?.name ?? null,

  companyAddress: variables.company
    ? `${variables.company.address}, ${variables.company.postcode} ${variables.company.city}`
    : null,

  companyNumber: variables.company
    ? variables.company.Regon
      ? `REGON ${variables.company.Regon}`
      : variables.company.NIP
        ? `NIP ${variables.company.NIP}`
        : variables.company.KRS
          ? `KRS ${variables.company.KRS}`
          : null
    : null,

  documentDate: variables.type === "vehicleHandover"
    ? variables.handoverDate
    : variables.returnDate,

  documentPlace: variables.company?.city ?? null,

  employerName: variables.company?.name ?? null,

  employerHQ: variables.company
    ? `${variables.company.postcode} ${variables.company.city}, ${variables.company.address}`
    : null,

  employerKRS: variables.company?.KRS ?? null,
  employerREGON: variables.company?.Regon ?? null,
  employerNIP: variables.company?.NIP ?? null,

  employeeName: variables.user ? `${variables.user.firstName} ${variables.user.lastName}` : null,
  employeeCompany: variables.company?.name ?? null,

  n11t1: variables.company?.name ?? null,
  n11t2: variables.company?.name ?? null,
})

export default ({
  validFrom,
  place,
  date,
  time,
  companyName,
  companyAddress,
  companyNumber,

  documentNumber,
  documentDate,
  documentPlace,

  employerName,
  employerHQ,
  employerEntered,
  employerKRS,
  employerREGON,
  employerNIP,
  employerRep,

  employeeName,
  employeeCardSeries,
  employeeCardNumber,
  employeeAddress,
  employeePostalcode,
  employeePesel,
  employeeLicense,
  employeeLicenseValid,
  employeeCompany,
  employeePosition,

  n11t1,
  n11t2
}: ResponsibilityVariables): TDocumentDefinitions => ({
  content: [
    { text: [place ?? empty, ', dnia ', date ?? empty, ' godzina ', time ?? empty], style: ['right', '10'] },
    { text: nl, style: ['7'] },
    { stack: [companyName ?? empty, companyAddress ?? empty, companyNumber ?? empty], style: ['10'] },
    { text: nl, style: ['12'] },
    { text: 'Umowa przydzielenia samochodu służbowego do eksploatacji przez Pracownika do celów związanych z wykonywaniem czynności służbowych', style: ['right', 'bold', '12'] },
    { text: ['nr ', documentNumber ?? empty], style: ['right', 'bold', '10'] },
    { text: 'ZZ-N-01', style: ['right', 'bold', '10'] },
    { text: nl, style: ['10'] },
    {
      text: [
        'Zawarta w dniu ',
        documentDate ?? empty,
        ' r. w ',
        documentPlace ?? empty,
        ' pomiędzy:'
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: [
        employerName ?? empty,
        ' z siedzibą w ',
        employerHQ ?? empty,
        ', wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego przez ',
        employerEntered ?? empty,
        ', pod numerem KRS ',
        employerKRS ?? empty,
        ', REGON ',
        employerREGON ?? empty,
        ', NIP ',
        employerNIP ?? empty,
        '.'
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: [
        'reprezentowaną przez ',
        employerRep ?? empty
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: 'a', style: ['11'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: [
        employeeName ?? empty,
        ', legitymującym się dowodem osobistym seria ',
        employeeCardSeries ?? empty,
        ' nr ',
        employeeCardNumber ?? empty,
        ' zamieszkałym: adres: ',
        employeeAddress ?? empty,
        ' kod pocztowy ',
        employeePostalcode ?? empty,
        ' PESEL ',
        employeePesel ?? empty,
        ', nr prawa jazdy ',
        employeeLicense ?? empty,
        ', data ważności prawa jazdy',
        employeeLicenseValid ?? empty,
        ', zatrudnionym: w ',
        employeeCompany ?? empty,
        ' na stanowisku: ',
        employeePosition ?? empty,
        ' w oparciu o umowę o pracę,'
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: [
        'zwanym dalej ',
        { text: 'Pracownikiem', style: ['bold'] },
        '.'
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 1', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: [
        'Na  podstawie niniejszej umowy Pracodawca powierza Pracownikowi z obowiązkiem zwrotu, w celu wykonywania obowiązków służbowych samochód służbowy o numerach i parametrach oraz wraz z wyposażeniem, które zostały wskazane w Protokole zdawczo- odbiorczym stanowiącym załącznik do niniejszej Umowy, a Pracownik przejmuje pełną odpowiedzialność materialną za stan samochodu oraz jego kompletność, w momencie podpisania ',
        { text: 'Protokołu zdawczo-odbiorczego samochodu służbowego', style: ['bold'] },
        ', stanowiącego załącznik do niniejszej umowy.'
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 2', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Pracownik oświadcza, że posiada ważne prawo jazdy i jednocześnie deklaruje, że jego aktualny stan zdrowia nie ogranicza zarówno jego kompetencji jak i zdolności do kierowania pojazdami mechanicznymi. W przypadku wymiany prawa jazdy Pracownik zobowiązany jest w terminie do 2 tygodni powiadomić Administratora Floty o jego wymianie.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 3', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Dowód rejestracyjny przechowywany jest w centrali, w przypadku wyjazdu pojazdu za  granicę, należy zgłosić się po dowód oraz upoważnienie do wyjazdu za granicę z wyprzedzeniem, do co najmniej 2-3 tygodni wcześniej, niż planowany wyjazd.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: 'Prowadzenie pojazdu bez posiadania przy sobie aktualnego prawa jazdy jest zakazane. Pracodawca przekazuje użytkownikowi kartę paliwową, którą użytkownik zobowiązuje się wykorzystywać zgodnie z instrukcjami udzielonymi przez przełożonego oraz Administratora Floty.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 4', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Pracownik zobowiązuje się odpowiadać za stan techniczny i zabezpieczenie powierzonego mu pojazdu oraz przejmuje pełną odpowiedzialność za jego prawidłową i zgodną z Prawem o ruchu drogowym eksploatację.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: 'Pracownik użytkujący samochód służbowy: odpowiada za wszelkie wykroczenia drogowe i ich skutki, a nadto odpowiada za wszelkie szkody wynikłe wskutek korzystania z samochodu.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: 'W przypadku kolizji drogowej, kiedy szkoda zostanie pokryta z OC sprawcy (osoby trzeciej), użytkownik zobowiązany jest wezwać Policję. Niewywiązanie się z tego obowiązku, powoduje obowiązek zapłaty przez Pracownika  kary w wysokości 100 zł.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: 'W przypadku pojazdów leasingowanych, osoba ukarana mandatem dodatkowo zostaje obciążona kwotą 50 zł, naliczaną przez firmę leasingującą pojazd za udzielanie odpowiedzi organom ścigania w związku np. z wykroczeniami drogowymi.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: 'W przypadku, gdy uszkodzenie pojazdu nastąpi z winy użytkownika, a koszty naprawy uregulowane będą z polisy AC Pracodawcy, użytkownik zobowiązany będzie do zapłaty kary w wysokości połowy kwoty netto naprawy, dla napraw o wartości do 500,00 zł oraz w wysokości 250,00 zł dla napraw o wartości przekraczającej 500,00 zł netto.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 5', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'W samochodach służbowych obowiązuje bezwzględny zakaz palenia tytoniu przez Pracownika jak i przebywających w jego wnętrzu pasażerów. W przypadku wykrycia oznak palenia Pracownik zobowiązany będzie do zapłaty kary w wysokości 250 zł.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 6', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Pracownik zobowiązuje się do wykonywania poleceń i zaleceń wydawanych przez Administratora Floty Samochodowej, związanych bezpośrednio z eksploatacją pojazdu oraz do poddawania się kwartalnym przeglądom estetyki samochodów, prowadzonych przez Menedżerów Regionalnych. Wyniki przeglądu obejmującego m.in. estetykę samochodu, uszkodzenia oraz stan zabezpieczeń (alarm, blokady, oznakowanie elementów) zgłaszane są do Administratora Floty.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 7', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Pracownik posiadając świadomość, że pojazd został mu przydzielony w celu optymalizacji wykonywanych przez niego obowiązków służbowych określonych w zakresie obowiązków stanowiącym integralną część umowy o pracę, zobowiązuje się używać go wyłącznie do tych celów, z zastrzeżeniem § 13.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: 'Pracownik stale użytkujący samochód służbowy może używać samochód służbowy do celów prywatnych wyłącznie po uzyskaniu pisemnej zgody przełożonego, z zastrzeżeniem § 13. Uzyskanie zgody jest równoznaczne z akceptacją zasad związanych z użyczeniem pojazdu służbowego do celów innych niż służbowe. W takim przypadku Pracodawca doliczy uzyskany przez Pracownika przychód z tytułu używania samochodu służbowego do celów prywatnych do innych przychodów uzyskiwanych przez Pracownika w danym miesiącu i odprowadzi stosowane zaliczki na podatek dochodowy od osób fizycznych oraz składki na ubezpieczenia społeczne i zdrowotne.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 8', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Pracownik zobowiązuje się nie wprowadzać jakiegokolwiek dodatkowego wyposażenia, niezgodnego ze standardem danego pojazdu bez zgody Administratora Floty. Dopuszcza się, że Administrator Floty Samochodowej może na powyższe wyrazić zgodę. Przy zmianie użytkownika lub wycofaniu pojazdu z eksploatacji ruchome elementy dodatkowego wyposażenia zabiera dotychczasowy użytkownik, wymaga to jednak powiadomienia Administratora.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 9', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Pracownik zobowiązuje się przedstawiać na żądanie Pracodawcy wykaz jazd dokonanych na przestrzeni miesiąca rozliczeniowego oraz uzasadnienia ich celowości, jakkolwiek Pracodawca nie określa z góry miesięcznych limitów przejeżdżanych kilometrów.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 10', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Pracownik zobowiązuje się systematycznie prowadzić Kartę pojazdu i okazywać ją na żądanie Pracodawcy i Administratora Floty.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 11', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: [
        'Pracownik zobowiązuje się nie przewozić osób trzecich w samochodzie służbowym z wyłączeniem Pracowników ',
        n11t1 ?? empty,
        ' oraz osób świadczących na rzecz ',
        n11t2 ?? empty,
        ' na podstawie stosunku prawnego innego niż stosunek pracy.'
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 12', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Przedmiotowy samochód może zostać oddany do dyspozycji innego lub innych Pracowników firmy na polecenie bezpośredniego przełożonego bądź Administratora Floty. Dyspozycja ta nosi nazwę użytkowania czasowego. Dotyczy to zwłaszcza przypadku choroby lub urlopu Pracownika użytkującego, wtedy samochód służbowy może zostać przekazany do eksploatacji innemu Pracownikowi na czas określony przez Menedżera Regionalnego. O przekazaniu pojazdu, Administrator Floty informowany jest mailem.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      text: 'Przekazanie odbywa się na podstawie protokołu zdawczo – odbiorczego, a czasowego użytkownika obowiązują w całej rozciągłości postanowienia niniejszej umowy. Używanie tego samochodu przez innych Pracowników do celów służbowych odbywa się po powiadomieniu Pracownika, któremu został on wcześniej powierzony protokołem zdawczo-odbiorczym. Przed ponownym przejęciem z użytkowania czasowego, stały użytkownik ma prawo zażądać ponownego formalnego przekazania. W takim przypadku osoba ta nie będzie odpowiadać za szkody, jakie powstały w czasie, gdy samochód pozostawał w użytkowaniu innej osoby lub osób.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 13', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      ol: [
        'Pracownik, w przypadku rozwiązania z nim umowy o pracę, ma obowiązek niezwłocznie, nie później niż w ostatnim dniu umowy o pracę, zwrócić samochód Pracodawcy.',
        'Pracownik, w przypadku zaplanowanej nieobecności w pracy trwającej dłużej niż 5 dni (takiej jak np. urlop wypoczynkowy, szkoleniowy, bezpłatny, macierzyński) ma obowiązek, na każde żądanie Pracodawcy lub Administratora Floty, przekazać samochód do dyspozycji Pracodawcy w miejscu i czasie wskazanym przez Pracodawcę lub Administratora Floty.',
        'W przypadku nieplanowanej nieobecności w pracy, spowodowanej zdarzeniami losowymi, trwającej dłużej niż 5 dni (związanej np. z przebywaniem Pracownika na zwolnieniu lekarskim), Pracownik ma obowiązek dochować wszelkich możliwych starań, aby zwrócić samochód Pracodawcy (w szczególności Pracownik ma obowiązek umożliwić Pracodawcy odbiór samochodu w miejscu ustalonym przez Strony oraz, w razie wystąpienia takiej konieczności, upoważnić inną osobę do zwrotu Pracodawcy samochodu).',
        'Niezależnie od powyższego Pracownik ma obowiązek zwrócić samochód na każde żądanie Pracodawcy.',
        'Zwrot/przekazanie samochodu następuje z wykorzystaniem protokołu zdawczo - odbiorczego.',
        'Niewywiązanie się z powyższych obowiązków stanowi ciężkie naruszenie obowiązków Pracowniczych, które może skutkować rozwiązaniem przez Pracodawcę umowy o pracę z Pracownikiem bez wypowiedzenia z winy Pracownika (w tzw. trybie dyscyplinarnym).'
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 14', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Pracownik użytkujący samochód służbowy zobowiązuje się:',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    {
      ul: [
        'przestrzegać terminów ważności ubezpieczeń;',
        'przestrzegać terminów badania technicznego pojazdu i dokonywać ww. przeglądy w uprawnionych stacjach wskazanych przez Administratora Floty lub firmę wynajmującą',
        'kontrolować stan zabezpieczeń samochodu (alarm, blokady, oznakowanie elementów);',
        'właściwie zabezpieczać samochód oraz parkować go po godzinach pracy w miejscu utrudniającym lub uniemożliwiającym jego uszkodzenie lub kradzież;',
        'w przypadku stwierdzenia jakichkolwiek usterek lub nieprawidłowości w działaniu pojazdu przekazywać samochód do autoryzowanej stacji obsługi wskazanej przez Administratora Floty, nie pozostawiać w samochodzie akcesoriów, sprzętów, wartościowych przedmiotów, urządzeń, dokumentów samochodowych, a także innych dokumentów, które pozostawione mogłyby spowodować narażenie na uszkodzenie samochodu (zakazane jest naprawianie jakichkolwiek uszkodzeń samodzielnie);',
        'w przypadku wystąpienia szkody, do poinformowania swojego przełożonego oraz w formie pisemnej (mail) Administratora Floty (na zgłoszenie szkody parkingowej Pracownik ma dwa dni robocze od momentu jej powstania, a w przypadku kolizji zobowiązany jest zgłosić ją w dniu, w którym powstała). W zakresie działań z likwidacją szkody, Pracownik zobowiązany jest postępować zgodnie z wytycznymi Administratora Floty oraz firmy wynajmującej;',
        'dokonywać miesięcznych rozliczeń z tytułu kosztów eksploatacji najpóźniej do 2 dnia następnego miesiąca po miesiącu, którego dotyczy rozliczenie.'
      ],
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 15', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Pracownik zgadza się przy zdaniu samochodu służbowego pokryć zawinione przez niego straty wynikające z różnicy między stanem początkowym a stanem końcowym uwidocznionymi w protokołach zdawczym i odbiorczym, wyliczone z uwzględnieniem współczynnika amortyzacji.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 16', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Umowa niniejsza obowiązuje od dnia jej zawarcia i nie traci swojej ważności w przypadku podpisania przez Pracownika kolejnych umów związanych ze świadczeniem pracy pod warunkiem zachowania ciągłości zatrudnienia. Umowa może być rozwiązana przez obie strony z zachowaniem jednotygodniowego okresu wypowiedzenia.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },
    { text: '§ 17', style: ['11', 'bold', 'center'], margin: [0, 5], lineHeight: 1.2 },
    {
      text: 'Umowę sporządzono w dwóch jednobrzmiących egzemplarzach, po jednym dla każdej ze stron. Strony zgodnie oświadczają, że w przypadku gdyby szkoda poniesiona przez Pracodawcę przekroczyła wartość określonych w umowie kar, Pracodawca uprawniony jest do dochodzenia od Pracownika odszkodowania w pełnej wysokości, a Pracownik wyraża zgodę na potrącenie kwoty szkody z przysługującego mu wynagrodzenia. W celu rozwiązania wątpliwości Pracownik oświadcza nadto, że wyraża zgodę na potrącenie z wynagrodzenia wszelkich należności wynikających z niniejszej umowy, które wynikają z naruszenia przez niego postanowień umowy.',
      style: ['11'],
      margin: [0, 5],
      lineHeight: 1.2
    },

    { text: nl, style: ['11'], margin: [0, 5], lineHeight: 1.2 },
    {
      columns: [
        {
          stack: [
            empty,
            { text: 'Zleceniodawca', margin: [0, 5] }
          ]
        },
        {
          stack: [
            empty,
            { text: 'Zleceniobiorca', margin: [0, 5] }
          ]
        }
      ],
      columnGap: 40,
      style: ['11', 'center'],
      margin: [0, 5],
      lineHeight: 1.2
    }
  ],
  header: [
    {
      text: ['Obowiązuje od: ', validFrom ?? empty],
      style: ['10'],
      lineHeight: 1.2,
      margin: [40, 40]
    }
  ],
  footer: (currentPage, pageCount) => [
    {
      columns: [
        '',
        {
          text: `Strona ${currentPage} z ${pageCount}`,
          style: ['8', 'right'],
          lineHeight: 1.2,
          margin: [40, 40]
        }
      ],
      columnGap: 10
    }
  ],
  defaultStyle: documentDefaultStyle,
  pageMargins: documentMargins,
  styles: documentStyles,
  images: documentImages,
})
