import { fields as protocolFields } from "@/assets/templates/pdf/protocol";
import { fields as damageFields } from "@/assets/templates/pdf/damage";
import { fields as responsibilityFields } from "@/assets/templates/pdf/responsibility";
import { fields as assignmentFields } from "@/assets/templates/pdf/assignment";

import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts";
import type { TDocumentDefinitions } from 'pdfmake/interfaces'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export enum DocumentType {
  Protocol = 'protocol',
  Damage = 'damage',
  Responsibility = 'responsibility',
  Assignment = 'assignment',
}

export const documentTemplates = {
  [DocumentType.Protocol]: () => import('@/assets/templates/pdf/protocol'),
  [DocumentType.Damage]: () => import('@/assets/templates/pdf/damage'),
  [DocumentType.Responsibility]: () => import('@/assets/templates/pdf/responsibility'),
  [DocumentType.Assignment]: () => import('@/assets/templates/pdf/assignment')
}

export interface VehicleDetails {
  [key: string]: any;
}

export interface Vehicle {
  details: VehicleDetails;
  [key: string]: any;
}

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  [key: string]: any;
}

export interface Company {
  name: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  postcode: string;
  NIP: string;
  Regon: string;
  KRS: string;
  [key: string]: any;
}

export interface AcceptanceVariables {
  user: User;
  company: Company;
  [key: string]: any;
}

export interface ResponsibilityVariables {
  vehicle: Vehicle;
  user: User;
  company: Company;
  handoverDate?: string;
  [key: string]: any;
}

export interface LimitsVariables {
  vehicle: Vehicle;
  [key: string]: any;
}

export interface SurveillanceVariables {
  user: User;
  company: Company;
  [key: string]: any;
}

export interface InstructionVariables {
  company: Company;
  [key: string]: any;
}

export type DocumentEditorFieldType =
  | "divider"
  | "text"
  | "number"
  | "textarea"
  | "date"
  | "time"
  | "select"
  | "radio"
  | "group"
  | "list"
  | "repeater";

export interface DocumentField {
  type: DocumentEditorFieldType;
  name: string;
  attrs?: { [key: string]: any };
  children?: Array<DocumentField>;
  options?: Array<{ text: string; value: any }>;
}

export default function useDocument() {
  const createDocument = async (type: DocumentType, variables: any): Promise<Blob> => {
    const template = (await documentTemplates[type]()).default(variables) as TDocumentDefinitions
    return await new Promise((resolve) => pdfMake.createPdf(template).getBlob(resolve))
  }

  const blobToFile = (blob: Blob, fileName: string) => new File([blob], fileName, { type: blob.type })

  const documnetEditorFields: {
    [key in DocumentType]: Array<DocumentField>;
  } = {
    [DocumentType.Protocol]: protocolFields,
    [DocumentType.Damage]: damageFields,
    [DocumentType.Responsibility]: responsibilityFields,
    [DocumentType.Assignment]: assignmentFields
  }

  return {
    createDocument,
    blobToFile,
    documnetEditorFields
  }
}
