import {
  documentDefaultStyle,
  documentImages,
  documentStyles,
  nl,
} from "@/helper/document";
import { DocumentField } from "@/use/document";
import moment from "moment";
import { TableCell, TDocumentDefinitions } from "pdfmake/interfaces";

export interface DamageVariables {
  damages: Array<{
    description: string;
    info: string;
  }>;
  [key: string]: any;
}

export const variablesToDamage = (variables: { [key: string]: any }) => ({
  damages: variables.vehicle?.damages?.map((damage: any) => ({
    description: damage.description,
    info: damage.createdAt
      ? `Zgłoszono ${moment(damage.createdAt).format("YYYY-MM-DD [o] HH:mm")}`
      : "",
  })),
});

export const fields: Array<DocumentField> = [
  {
    type: "repeater",
    name: "damages",
    attrs: {},
    children: [
      {
        type: "textarea",
        name: "description",
        attrs: { label: "Szkoda" },
      },
      {
        type: "textarea",
        name: "info",
        attrs: { label: "Informacja o szkodzie" },
      },
    ],
  },
];

export default ({ damages }: DamageVariables): TDocumentDefinitions => ({
  content: [
    {
      text:
        "INFORMACJA O STANIE SZKÓD NA SAMOCHODZIE (DOTYCZY SAMOCHODÓW WYNAJMOWANYCH)",
      style: ["italics", "8"],
    },
    { text: nl, lineHeight: 1 },
    {
      table: {
        widths: ["*", 150],
        body: [
          [
            {
              text: "Szkoda",
              style: ["bold", "12", "fill-dark", "text-white"],
              margin: [0, 10, 0, 0],
            },
            {
              text: [
                { text: "Informacja o szkodzie", style: ["bold", "12"] },
                nl,
                {
                  text:
                    "(zgłoszona na infolinia, data zgłoszenia, kwalifikacja do naprawy)",
                  style: ["8"],
                },
              ],
              style: ["fill-dark", "text-white"],
              lineHeight: 1,
            },
          ],
          ...(damages?.length
            ? damages.map((damage): TableCell[] => [
                { text: damage.description ?? nl, margin: [0, 5] },
                { text: damage.info ?? nl, margin: [0, 5] },
              ])
            : []),

          ...Array.from({
            length: Math.max(16 - (damages?.length ?? 0), 0),
          }).map(() => [
            { text: nl, margin: [0, 5] },
            { text: nl, margin: [0, 5] },
          ]),
        ],
        dontBreakRows: true,
      },
      lineHeight: 1,
      style: ["fill-light"],
    },
    { text: nl, lineHeight: 1 },
    { text: nl, lineHeight: 1 },
    { text: nl, lineHeight: 1 },
    { text: nl, lineHeight: 1 },
    { text: nl, lineHeight: 1 },
    {
      text:
        "......................................................................",
      lineHeight: 1,
      style: ["8"],
    },
    {
      text: [
        "podpis osoby ",
        { text: "zdającej", style: ["bold"] },
        " samochód",
      ],
      lineHeight: 1,
      style: ["8"],
    },
  ],
  defaultStyle: documentDefaultStyle,
  pageMargins: [40, 40],
  styles: documentStyles,
  images: documentImages,
});
